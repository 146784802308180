// extracted by mini-css-extract-plugin
export const left = "sidebarLeft-module--left--1GttZ";
export const listPosts = "sidebarLeft-module--listPosts--202CA";
export const sidePostLeft = "sidebarLeft-module--sidePostLeft--2OTOM";
export const listTitle = "sidebarLeft-module--listTitle--1Ck-o";
export const titleLink = "sidebarLeft-module--titleLink--e6l6k";
export const content = "sidebarLeft-module--content--2h6nf";
export const post = "sidebarLeft-module--post--uKm77";
export const postImage = "sidebarLeft-module--postImage--2e3BW";
export const postDetails = "sidebarLeft-module--postDetails--2ZTGC";
export const title = "sidebarLeft-module--title--2vCno";
export const date = "sidebarLeft-module--date--1Ek4-";
export const adSpace = "sidebarLeft-module--adSpace--VsENk";
export const meta = "sidebarLeft-module--meta--3AIww";
export const category = "sidebarLeft-module--category--2iBV7";
export const excerpt = "sidebarLeft-module--excerpt--1qpCr";
export const sidePostImage = "sidebarLeft-module--sidePostImage--2MM0U";