// extracted by mini-css-extract-plugin
export const container = "topSlider-module--container--1PPZe";
export const wrapper = "topSlider-module--wrapper--3bUOz";
export const bigSlide = "topSlider-module--bigSlide--GG4mT";
export const overlayLink = "topSlider-module--overlayLink--wD2z2";
export const bigPostImage = "topSlider-module--bigPostImage--2b1Dt";
export const meta = "topSlider-module--meta--1NBXj";
export const metaBox = "topSlider-module--metaBox--1irco";
export const category = "topSlider-module--category--3gtn3";
export const title = "topSlider-module--title--2M3h4";
export const titleLink = "topSlider-module--titleLink--1f-Xg";
export const smallSlides = "topSlider-module--smallSlides--qAn5Z";
export const horizontalPost = "topSlider-module--horizontalPost--PlGfe";
export const horizontalPostImage = "topSlider-module--horizontalPostImage--4g3Qe";
export const boxedPostImage = "topSlider-module--boxedPostImage--nqzgq";
export const boxedPosts = "topSlider-module--boxedPosts--1vHpP";
export const boxedLeft = "topSlider-module--boxedLeft--1heva";
export const boxedRight = "topSlider-module--boxedRight--C81o2";