// extracted by mini-css-extract-plugin
export const right = "sidebarRight-module--right--3_D9V";
export const listPosts = "sidebarRight-module--listPosts--1ndfa";
export const listTitle = "sidebarRight-module--listTitle--3-nI7";
export const titleLink = "sidebarRight-module--titleLink--2VRLD";
export const content = "sidebarRight-module--content--1Hcet";
export const post = "sidebarRight-module--post--D_FHK";
export const postImage = "sidebarRight-module--postImage--vLJwK";
export const postDetails = "sidebarRight-module--postDetails--k7DVE";
export const title = "sidebarRight-module--title--1ZVjT";
export const date = "sidebarRight-module--date--2Xz2M";
export const adSpace = "sidebarRight-module--adSpace--1YGVL";
export const sidePost = "sidebarRight-module--sidePost--16yZ-";
export const meta = "sidebarRight-module--meta--3e8x-";
export const category = "sidebarRight-module--category--KZICD";
export const excerpt = "sidebarRight-module--excerpt--2qUzk";
export const sidePostImage = "sidebarRight-module--sidePostImage--22fiQ";