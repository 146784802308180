// extracted by mini-css-extract-plugin
export const middle = "midContent-module--middle--3TG-C";
export const titleLink = "midContent-module--titleLink--1Lt19";
export const bigPost = "midContent-module--bigPost--iM5cg";
export const bigPostImage = "midContent-module--bigPostImage--2xZ9T";
export const bigPostDetails = "midContent-module--bigPostDetails--1ipVr";
export const meta = "midContent-module--meta--1HRBO";
export const category = "midContent-module--category--1faPf";
export const date = "midContent-module--date--tsTgf";
export const bigPostTitle = "midContent-module--bigPostTitle--1r8G-";
export const excerpt = "midContent-module--excerpt--3ciFl";
export const adSpace = "midContent-module--adSpace--1GffY";
export const middleTwoColumnLink = "midContent-module--middleTwoColumnLink--3iWUv";
export const linkWrapperLeft = "midContent-module--linkWrapperLeft--2wWTP";
export const linkWrapperRight = "midContent-module--linkWrapperRight--24OMX";
export const linkTitle = "midContent-module--linkTitle--2gheV";
export const linkExcerpt = "midContent-module--linkExcerpt--UD9dm";
export const postOverlay = "midContent-module--postOverlay--1gRY-";
export const overlayImage = "midContent-module--overlayImage--2Azb-";
export const title = "midContent-module--title--3Lfev";